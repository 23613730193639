<template>
  <b-container fluid>
    <b-modal id="my-modal" title="Descripción Competencia" body-bg-variant="dark" header-bg-variant="dark" footer-bg-variant="dark" size="md" hide-footer>
      <div class="text-center">
        <div class="row">
          <div class="col-12 text-left">
            <span> Nombre Competencia: </span>
            <p class="mt-3">
              {{ row.titulo }}
            </p>
          </div>
          <div class="col-12 text-left mt-3">
            <span> Tipo Competencia: </span>
            <p class="mt-3">
              {{ row.tipoCompetencia.descripcion }}
            </p>
          </div>
          <div class="col-12 text-left mt-3">
            <span> Descripción: </span>
            <p class="mt-3">
              {{ row.descripcion }}
            </p>
          </div>
        </div>
      </div>
    </b-modal>
    <b-row>
      <b-col sm="12">
        <card>
          <template v-slot:headerTitle>
            <h4 class="card-title"><b>LISTA DE COMPETENCIAS</b></h4>
          </template>
          <div class="text-right px-4 mt-4 responsive-xl-md">
            <div class="d-flex justify-content-end">
              <vs-button icon   animation-type="vertical" success class="mx-1 text-right" v-if="usuario.rol.nivel == 4" @click="$router.push('Competencias_agregar')">
                <i class="fas fa-plus px-1"></i>
                Agregar Competencia
                <template #animate>
                  <i class="fas fa-plus"></i>
                </template>
              </vs-button>
            </div>
          </div>
          <!-- button-sm -->
          <div class="text-right px-4 mt-4 pb-0 mb-0 responsive-sm" style="margin-bottom: -73px !important; z-index: 999 !important">
            <div class="d-flex justify-content-end">
              <vs-button   success class="btn-circle" style="background-color: rgb(54, 153, 100) !important" @click="$router.push('Competencias_agregar')"
                ><i class="fas fa-plus"></i
              ></vs-button>
            </div>
          </div>
          <!-- end button-sm -->
          <div class="table-responsive px-4 mt-3">
            <table id="datatable_areas" class="table table-bordered table-responsive-md table-striped text-center">
              <thead>
                <tr>
                  <th>Título competencia</th>
                  <th>Tipo de competencia</th>

                  <!-- <th>Estado</th> -->
                  <th v-if="usuario.rol.nivel == 4">Opciones</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in listaItems" :key="item.id">
                  <td>{{ item.titulo }}</td>
                  <td>{{ item.tipoCompetencia.descripcion }}</td>
                  <td v-if="usuario.rol.nivel == 4" style="width: 150px !important"><ButtonGroup :dataModal="item" :Id="item.id" :data="dataIcons"></ButtonGroup></td>

                  <!-- <td>
                    <button @click="showModal(item)" class="btn btn-secondary">Ver descripción</button>
                  </td> -->

                  <!-- <td v-if="usuario.rol.nivel == 4">
                    <span class="pointer">
                      <i @click="$router.push('Competencias_editar/' + item.id)" class="fas fa-solid fa-pen px-1 text-success"></i>
                      <i @click="deleteRow(item.id)" class="fas fa-solid fa-trash px-1 text-danger"></i>
                    </span>
                  </td> -->
                </tr>
              </tbody>
            </table>
          </div>
        </card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from "../../../config/pluginInit";
import ButtonGroup from "../../../components/ButtonGroup/ButtonGroup.vue";
const Swal = require("sweetalert2");

export default {
  components: {
    ButtonGroup,
  },
  name: "DataTable",
  data() {
    return {
      listaItems: [],
      logoEmpresa: "",
      usuario: {},
      row: {
        titulo: "",
        tipoCompetencia: {
          descripcion: "",
        },
        descripcion: "",
      },
      dataIcons: [
        {
          type: "success",
          tooltip: "Editar competencias",
          title: "",
          value: 10,
          iconName: "pen",
          color: "rgba(22, 162, 117, 1)",
          tipo: "squared",
          animated: "bounce",
          onActivate: (Id) => {
            console.log("Activated");
            this.$router.push("competencias_editar/" + Id);
          },
          onDeactivate: () => {
            console.log("Deactivate");
          },
        },
        {
          type: "primary",
          tooltip: "Ver descripción",
          title: "",
          value: 999,
          iconName: "eye",
          color: "rgba(22, 162, 117, 1)",
          tipo: "squared",
          animated: "bounce",

          onActivate: (dataModal) => {
            console.log("Activated 999 -->", dataModal);
            // this.$bvModal.push()
            this.showModal(dataModal);
          },
          onDeactivate: () => {
            console.log("Deactivate");
          },
        },
        {
          type: "danger",
          tooltip: "Eliminar registro",
          title: "",
          value: () => 0,
          iconName: "trash",
          color: "rgba(22, 162, 117, 1)",
          tipo: "squared",
          animated: "bounce",
          onActivate: (Id) => {
            console.log("Activated");
            this.deleteRow(Id);
          },
          onDeactivate: () => {
            console.log("Deactivate");
          },
        },
      ],
    };
  },
  async mounted() {
    try {
      this.$isLoading(true);
      this.usuario = JSON.parse(localStorage.getItem("setPersonaAct"));
      await this.getData();
      core.index();
      window.$("#datatable_areas").DataTable({
        language: {
          url: "//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json",
        },
      });
      this.$isLoading(false);
    } catch (error) {
      this.$isLoading(false);
      console.log("error", error);
    }
  },
  methods: {
    showModal(item) {
      this.row = item;
      this.$bvModal.show("my-modal");
    },
    async deleteRow(id) {
      Swal.fire({
        title: "Seguro eliminar registro?",
        text: "Después de eliminarlo no podrás recuperarlo!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Si!",
        cancelButtonText: "Cancelar",
      }).then(async (result) => {
        if (result.isConfirmed) {
          //let loader = this.$loading.show();
          await this.$store.dispatch("hl_get", {
            path: "Competencia/Delete/" + id,
          });

          //console.log("res", res);
          //loader.hide()
          await this.getData();

          Swal.fire("Listo!", "Registro eliminado correctamente.", "success");
        }
      });
    },
    async getData() {
      //console.log(this.persona.empresa.id);
      try {
        console.log("departamentoss.....................");
        let res = await this.$store.dispatch("hl_post", {
          path: "Competencia/ListCompetencia/",
        });
        console.log("post departamento... ", res);
        if (res.length > 0) {
          this.listaItems = res;
        } else {
          this.listaItems = [];
        }
      } catch (error) {
        this.listaItems = [];
        console.log("err", error);
      }
    },
  },
};
</script>
<style lang="scss"></style>
